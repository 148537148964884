<template>
  <form  @submit.prevent="verifyOtp" >
    <div class="d-flex align-items-center justify-content-center">
      <div class="login-panel-wrapper text-center">
        <div
          v-if="error"
          class="modal-alert modal-alert_advice text-center mb-4">
          {{ error }}
        </div>
        <h5><strong>Authentication Verification</strong></h5>
        <img alt="lock" src="@/assets/icons/lock.svg" width="50px" height="50px" class="my-3" />
        <span v-if="!authForm.is2faEnable">
          <p>Scan the image below with the "Google Authenticator" app on your phone.</p>
          <img class="mb-2" :src="authForm.qrCode" />
        </span>
        <div class="form-group full-width">
          <p><strong>Enter the code form the application</strong></p>
          <span v-if="!authForm.is2faEnable">
            <p>After scanning the QR code image, the app will display a code that you can enter below.</p>
          </span>
          <p v-else>
            Open the two-factor-authenticator app on your mobile device to view your authentication code.
          </p>
          <input type="number" id="otp" class="form-control verify-code" v-model="otp" placeholder="6-digit code" required>
          <div class="form-check">
            <input v-model="isRemembered" class="form-check-input" type="checkbox" id="fieldIsRemeber">
            <label class="form-check-label" for="fieldIsRemeber">Remember for 60 days</label>
          </div>
        </div>
        <button :disabled="ui.submit">
          <div class="spinner-border spinner-border-sm" role="status" v-if="ui.submit">
            <span class="visually-hidden">Loading...</span>
          </div>
          <span v-else>Verify</span>
        </button>
        <!-- <div class="text-center">
            <router-link :to="{ name: 'reset_pass' }" class="forgot-pass">
            Recover account?
            </router-link>
        </div> -->
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'twoFactorAuth',
  props: { authForm: { type: Object, required: true } },
  data() {
    return {
      ui: {
        submit: false
      },
      id: null,
      error: null,
      qrCode: null,
      is2faEnable: false,
      otp: null,
      isRemembered: true
    }
  },
  methods: {
    verifyOtp() {
      this.ui.submit = true;
      this.error = null;
      this.$http.post(`/api/v1/auth/verifyOtp/${this.authForm.id}`, {
        otp: this.otp,
        token: this.authForm.secretToken
      })
      .then((res) => {
        if (res.data.success) {
          if (this.isRemembered) {
            this.$cookie.set(this.authForm.email, true, 60);
          }
          this.$store.dispatch('SET_USER', res.data.data)
          window.location.href = '/';
        } else {
          this.error = 'Two-factor authentication failed.';
          this.otp = null;
        }
      })
      .catch((err) => {
        this.error = err.response.data.message ? err.response.data.message : err.response.statusText;
        this.otp = null;
      })
      .finally(() => {
        this.ui.submit = false;
      })
    }
  }
}

</script>
<style lang="scss" scoped>
.login-panel {
  min-height: 540px;
  max-width: 363px;
  width: 100%;
  border: 1px solid #E7E8E8;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 18px 32px 0 rgba(0,0,0,0.06);
  margin: 50px auto;
  padding: 20.5px 30px;
  &-subtitle {
    color: rgba(0,0,0, .7);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 24px;
  }
  &-logo {
    padding-bottom: 15.5px;
    border-bottom: 1px solid #E7E8E8;
    margin-bottom: 14.5px;
  }
  &-wrapper {
    padding-bottom: 19.5px;
  }
  .forgot-pass {
    display: inline-block;
    color: rgba(0,0,0, .6);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 12px;
    margin-top: 20px;
  }
  label {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 11px;
  }
  .verify-code {
    display: block;
    height: 44px;
    width: 100%;
    border: 1px solid #D4D4D4;
    border-radius: 8px;
    background-color: #FFFFFF;
    opacity: 1;
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
    margin-bottom: 18px;
    padding-left: 12px;
    &:last-child {
      margin-bottom: 12px;
    }
    &::placeholder {
      opacity: 0.5;
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
    }
  }
  button {
    width: 100%;
    height: 44px;
    border-radius: 8px;
    background-color: #266FE5;
    border: none;
    color: #FFFFFF;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    margin-top: 18px;
    transition: all .3s;
    &:hover {
      background-color: #175fd2;
    }
  }
  .powered-by {
    color: #0B1B1C;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 12px;
    margin-right: 12px;
  }
}
.g-recaptcha {
  transform-origin: left top;
  -webkit-transform-origin: left top;
}

.form-check-input {
  width:auto !important;
}

.form-check-input:checked {
  width:auto !important;
}

</style>
