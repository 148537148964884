<template>
  <div class="login-panel">
    <form v-on:submit.prevent="login">
      <div class="login-panel-logo d-flex justify-content-center">
        <img src="@/assets/images/logo-vision.svg" alt="vision">
      </div>
      <form @submit.prevent="login" v-if="isLogin">
        <div class="login-panel-wrapper">
          <div class="form-group full-width">
            <label for="email">Email</label>
            <input type="email" id="email" class="form-control" v-model="email" required>
          </div>
          <div class="form-group full-width">
            <label for="password">Password</label>
            <input type="password" id="password" class="form-control" v-model="password" required>
          </div>
          <div class="text-center mt-3">
            <div class="g-recaptcha d-inline-block" :data-sitekey="sitekey"></div>
          </div>
          <button :disabled="ui.submit">
            <div class="spinner-border spinner-border-sm" role="status" v-if="ui.submit">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span v-else>Login</span>
          </button>
          <div class="text-center">
            <router-link :to="{ name: 'reset_pass' }" class="forgot-pass">
              Forgot Password?
            </router-link>
          </div>
        </div>
        <br>
        <div class="d-flex align-items-center justify-content-center">
          <div class="powered-by">
            Powered by
          </div>
          <img src="@/assets/images/logo-clearmortgagecapital.svg" alt="vision">
        </div>
        <p class="text-center mt-4 mb-0 color-red" v-if="error">{{ error }}</p>
      </form>

      <transition name="component-fade" mode="out-in" v-else>
        <two-factor-auth :authForm="authenticationForm" />
      </transition>
    </form>
  </div>
</template>

<script>
import twoFactorAuth from "./2fa.vue";
export default {
  name: 'Login',
  components: {
    twoFactorAuth
  },
  data() {
    return {
      ui: {
        submit: false
      },
      authenticationForm: {
        id: null,
        qrCode: null,
        is2faEnable: false,
        secretToken: null,
        email: null
      },
      email: null,
      password: null,
      error: null,
      sitekey: null,
      isLogin: true,
    }
  },
  methods: {
    login(event) {
      this.error = null
      let recaptchaResponse = event.target['g-recaptcha-response'].value;
      if (recaptchaResponse) {
        this.ui.submit = true;
        this.$http.post('/api/v1/auth/login', {
          email: this.email,
          password: this.password,
          recaptcha: recaptchaResponse,
          isRemembered: this.$cookie.get(this.email) ? 1 : 0
        })
        .then((res) => {
          if (!res.data.success) {
            this.prepareAuthFormFor2fa(res.data);
          } else {
            this.$store.dispatch('SET_USER', res.data.data)
            this.$store.dispatch('SET_FLYER_ID', res.data.data.flyerId)
            window.location.href = '/';
          }
        })
        .catch((err) => {
          this.error = err.response.data
        })
        .finally(() => {
          this.ui.submit = false;
        })
      } else {
        this.error = 'G-reCaptcha is required!';
      }
    },
    prepareAuthFormFor2fa(response) {
      this.isLogin = false;
      this.authenticationForm.is2faEnable = response.is2faEnable;
      this.authenticationForm.id = response.data.id;
      this.authenticationForm.email = response.data.email;
      this.authenticationForm.secretToken = response.data.secretToken;
      this.authenticationForm.qrCode = `https://chart.apis.google.com/chart?cht=qr&chs=150x150&chl=${response.data.token}`;
    }
  },
  mounted() {
    this.sitekey = process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY

    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
    document.head.appendChild(recaptchaScript)
  }
}
</script>
<style lang="scss" scoped>
.login-panel {
  min-height: 540px;
  max-width: 363px;
  width: 100%;
  border: 1px solid #E7E8E8;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 18px 32px 0 rgba(0,0,0,0.06);
  margin: 50px auto;
  padding: 20.5px 30px;
  &-subtitle {
    color: rgba(0,0,0, .7);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 24px;
  }
  &-logo {
    padding-bottom: 15.5px;
    border-bottom: 1px solid #E7E8E8;
    margin-bottom: 14.5px;
  }
  &-wrapper {
    padding-bottom: 19.5px;
    border-bottom: 1px solid #E7E8E8;
  }
  .forgot-pass {
    display: inline-block;
    color: rgba(0,0,0, .6);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 12px;
    margin-top: 20px;
  }
  label {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 11px;
  }
  input {
    display: block;
    height: 44px;
    width: 100%;
    border: 1px solid #D4D4D4;
    border-radius: 8px;
    background-color: #FFFFFF;
    opacity: 1;
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
    margin-bottom: 18px;
    padding-left: 12px;
    &:last-child {
      margin-bottom: 12px;
    }
    &::placeholder {
      opacity: 0.5;
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
    }
  }
  button {
    width: 100%;
    height: 44px;
    border-radius: 8px;
    background-color: #266FE5;
    border: none;
    color: #FFFFFF;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    margin-top: 18px;
    transition: all .3s;
    &:hover {
      background-color: #175fd2;
    }
  }
  .powered-by {
    color: #0B1B1C;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 12px;
    margin-right: 12px;
  }
}
.g-recaptcha {
  transform-origin: left top;
  -webkit-transform-origin: left top;
}
</style>
